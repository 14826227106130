import { ref, watch, computed } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import orderRepository from '@/repository/orderRepository'
// import ability from '@/libs/acl/ability'

export default function saleList() {
  const toast = useToast()

  const refActionProductTable = ref(null)
  const userId = ref(null)

  const perPage = ref(10)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const selectRules = ref([])

  const dataMeta = computed(() => {
    const localItemsCount = refActionProductTable.value ? refActionProductTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItems.value,
    }
  })

  const refetchData = () => {
    refActionProductTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  // const toastAccessError = text => {
  //   toast({
  //     component: ToastificationContent,
  //     props: {
  //       title: 'Ошибка редактирования номенклатуры',
  //       icon: 'AlertTriangleIcon',
  //       variant: 'danger',
  //       text,
  //     },
  //   })
  // }

  const fetchItems = (ctx, callback) => {
    orderRepository.elementList({
      query: searchQuery.value,
      page: currentPage.value,
      limit: perPage.value,
      sord: isSortDirDesc.value ? 'desc' : 'asc',
      sidx: sortBy.value,
      userId: userId.value,
    })
      .then(response => {
        const { items, count } = response
        if ((items || []).length === 0) {
          callback([])
        }

        callback(items.map(item => ({
          ...item,
          createdUser: !item.createdUser ? '' : `${item.createdUser.firstName || ''} ${item.createdUser.lastName || ''}`,
          updatedUser: !item.updatedUser ? '' : `${item.updatedUser.firstName || ''} ${item.updatedUser.lastName || ''}`,
        })))
        totalItems.value = count
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Ошибка при извлечении документов',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error ? `${error}`.replace('Error: ', '') : '',
          },
        })
      })
  }

  const clearSelectTable = () => {
    refActionProductTable.value.clearSelected()
  }

  const selectRowTable = val => {
    selectRules.value = val
  }

  const fetchElementList = () => new Promise(resolve => {
    orderRepository.elementList({
      query: searchQuery.value,
      page: currentPage.value,
      limit: perPage.value,
      sord: isSortDirDesc.value ? 'desc' : 'asc',
      sidx: sortBy.value,
      userId: userId.value,
    })
      .then(res => {
        resolve(res)
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Ошибка при извлечении документов',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error ? `${error}`.replace('Error: ', '') : '',
          },
        })
        resolve([])
      })
  })

  // const updateElement = (id, active) => new Promise(resolve => {
  //   if (ability.can('update', 'ActionManage')) {
  //     const param = new FormData()
  //     param.append('active', parseInt(active, 10) === 1 ? 0 : 1)
  //     actionRepository.updateElement(id, param)
  //       .then(() => {
  //         resolve(true)
  //       })
  //       .catch(error => {
  //         toast({
  //           component: ToastificationContent,
  //           props: {
  //             title: 'Ошибка удаления элемента',
  //             icon: 'AlertTriangleIcon',
  //             variant: 'danger',
  //             text: error ? `${error}`.replace('Error: ', '') : '',
  //           },
  //         })
  //       })
  //   } else {
  //     toastAccessError('Недостаточно прав для изменения')
  //   }
  // })

  // const deleteElement = id => new Promise(resolve => {
  //   if (ability.can('delete', 'ActionManage')) {
  //     actionRepository.deleteElement(id)
  //       .then(() => {
  //         resolve(true)
  //       })
  //       .catch(error => {
  //         toast({
  //           component: ToastificationContent,
  //           props: {
  //             title: 'Ошибка удаления элемента',
  //             icon: 'AlertTriangleIcon',
  //             variant: 'danger',
  //             text: error ? `${error}`.replace('Error: ', '') : '',
  //           },
  //         })
  //       })
  //   } else {
  //     toastAccessError('Недостаточно прав для удаления')
  //   }
  // })

  return {
    fetchItems,
    perPage,
    currentPage,
    totalItems,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refActionProductTable,

    refetchData,
    clearSelectTable,
    selectRowTable,

    selectRules,
    // updateElement,
    // deleteElement,
    fetchElementList,

    userId,
  }
}
