<template>
  <div>
    <b-card>
      <b-row>
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Показывать по</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
          <label>записей</label>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Поиск по документам (товарам)..."
            />
          </div>
        </b-col>
      </b-row>
    </b-card>

    <div style="max-width: 1200px;">
      <i-collapse-item-card
        v-for="item in elementList"
        :key="item.id"
        :item="item"
      >
        <template slot="before-title">
          <b-badge variant="light-info">
            {{ item.status && item.status.title ? item.status.title : '' }}
          </b-badge>
        </template>
        <template slot="title">
          <div class="h5">
            Заявка № {{ item.id }} от {{ formatDate(item.createdAt) }}
          </div>
        </template>
        <template slot="badge">
          <div class="text-primary font-weight-bold">
            {{ item.bonusApproved }} из {{ item.bonusTotal }} B
          </div>
        </template>
        <template slot="after-title">
          <div v-if="!item.teamId">
            (персональная)
          </div>
          <div v-else>
            (команда - "{{ item.team && item.team.title ? item.team.title : '' }}")
          </div>
        </template>
        <template v-if="(item.items || []).length > 0">
          <sale-products :items="item.items" />
        </template>
      </i-collapse-item-card>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  BRow, BCol, BCard, BFormInput, BBadge,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ICollapseItemCard from '@/components/i-collapse-link-card/ICollapseItemCard.vue'
import { formatDate } from '@core/utils/filter'
import saleList from './saleList'
import SaleProducts from './SaleProducts.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormInput,
    BBadge,
    vSelect,

    ICollapseItemCard,
    SaleProducts,
  },
  setup() {
    const elementList = ref([{}])

    const {
      fetchItems,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      fetchElementList,
    } = saleList()

    fetchElementList()
      .then(res => {
        elementList.value = res.items
      })

    return {
      fetchItems,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      elementList,

      formatDate,
    }
  },
}
</script>

<style lang="scss" scoped>
.title-wrap {
    display: grid;
    grid-template-columns: 1fr 5fr 2fr;
    width: 95%;
}
</style>
