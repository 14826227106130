<template>
  <div>
    <b-card
      v-for="item in items"
      :key="item.id"
      class="sale-product-item border mb-0"
      no-body
    >
      <div class="product-img text-center">
        <b-img
          rounded
          fluid
          :src="item.file && item.file.path ? item.file.path : '/no-image.png'"
        />
      </div>
      <div>
        <h3>{{ item.title }}</h3>

        <b-card-text class="item-company mb-0">
          <div class="item-property  text-muted font-small-3">
            <div class="item-property-label">
              Производитель
            </div>
            <div class="item-property-text">
              {{ item.brand }}
            </div>
            <div class="item-property-label">
              Артикул
            </div>
            <div class="item-property-text">
              {{ item.articul }}
            </div>
          </div>
          <div class="d-flex align-items-left align-bottom justify-content-start mt-2 mb-1">
            <b-img :src="homeBonus" />
            <b-img
              v-if="item.actionPrice && item.actionPrice.bonus"
              :class="{'ml-1': item.actionPrice && item.actionPrice.bonus}"
              :src="arrowPrice"
            />
            <div
              class="price-main font-weight-bold"
              :class="{'ml-1': !item.actionPrice || !item.actionPrice.bonus}"
            >
              {{ item.actionPrice.bonus && item.actionPrice.bonus ? item.actionPrice.bonus.profit : item.actionPrice.standard && item.actionPrice.standard.profit ? item.actionPrice.standard.profit : '5' }}B
            </div>
            <div
              v-if="item.actionPrice.bonus && item.actionPrice.bonus"
              class="price-old text-muted small"
            >
              <s>{{ item.actionPrice && item.actionPrice.standard && item.actionPrice.standard.profit ? item.actionPrice.standard.profit : '5' }}B</s>
            </div>
          </div>
        </b-card-text>
      </div>
    </b-card>
  </div>

</template>

<script>
/* eslint-disable global-require */
import { BCard, BCardText, BImg } from 'bootstrap-vue'
// import Ripple from 'vue-ripple-directive'
// import { formatDate } from '@core/utils/filter'
// import { generateRandomVariant } from '@/utils/UserFilter'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BCardText,
    BImg,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      arrowPrice: require('@/assets/images/price-group/arrow_price.svg'),
      boxQuantity: require('@/assets/images/price-group/box_quantity.svg'),
      homeBonus: require('@/assets/images/price-group/home_bonus.svg'),
    }
  },
}
</script>

<style lang="scss" scoped>
.sale-product-item {
  padding: 1.5rem;
  box-shadow: none;
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr 3fr;
  .product-img {
    img {
      height: 120px;
    }
  }
  .item-property {
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr 3fr;
    font-weight: 500;
    text-align: left;
    .item-property-label {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .item-property-text {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}
.price-main{
  font-size: 24px;
  line-height: 36px;
  padding-right: 10px;
}
.price-old{
  margin-top: 10px;
}
.price-quantity {
  font-size: 16px;
  font-weight: 500;
}
</style>
